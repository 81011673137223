<template>
  <div class="info-popup-container" :class="{'visible': isVisible}">
    <div class="info-popup">
      <div class="header">
        <div class="close-button" @click="hide">
          <img src="/images/icon-close.svg"/>
        </div>
      </div>
      <div class="title">
        {{title}}
      </div>
      <div class="text" v-html="text"/>
    </div>
  </div>
</template>
<script>

export default {
  name: 'InfoPopup',
  data() {
    return {
      isVisible: false,
      title: '',
      text: '',
    }
  },
  methods: {
    show(item) {
      document.body.classList.add('no-scroll')
      this.title = item.title
      this.text = item.description
      this.isVisible = true
    },
    hide() {
      document.body.classList.remove('no-scroll')
      this.isVisible = false
    }
  }
}
</script>
