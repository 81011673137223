<template>
  <div>
    <div class="app-list-title">Улики</div>
    <div class="app-list">
      <div
          v-for="evidence in evidences"
          :key="evidence.code"
          class="list-item-wrapper"
      >
        <div
            class="list-item"
            :class="{'active': evidence.isSelected, 'disabled': evidence.isDisabled}"
            @click="toggleState(evidence)"
        >
          {{evidence.title}}
        </div>
        <div class="list-info-button" @click="showInfo(evidence)">
          <span class="info-icon">i</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'EvidencesList',
  props: ['evidences'],
  methods: {
    toggleState(evidence) {
      if (evidence.isSelected) {
        evidence.isSelected = false
        evidence.isDisabled = true
      } else if (evidence.isDisabled) {
        evidence.isDisabled = false
      } else {
        evidence.isSelected = true
      }
      this.$emit('change')
    },
    showInfo(evidence) {
      this.$emit('evidenceInfo', evidence)
    }
  }
}
</script>
