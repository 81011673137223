<template>
  <div>
    <div class="app-list-title">Призраки</div>
    <div class="app-list cols-3">
      <div
          v-for="ghost in ghosts"
          :key="ghost.code"
          class="list-item-wrapper"
      >
        <div
            class="list-item"
            :class="{'active': ghost.isSelected, 'disabled': ghost.isDisabled}"
            @click="toggleState(ghost)"
        >
          {{ghost.title}}
        </div>
        <div class="list-info-button" @click="showInfo(ghost)">
          <span class="info-icon">i</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'GhostsList',
  props: ['evidences', 'ghosts'],
  methods: {
    toggleState(ghost) {
      ghost.isSelected = !ghost.isSelected
    },
    showInfo(ghost) {
      this.$emit('ghostInfo', ghost)
    },
    checkEvidences() {
      const selectedEvidences = []
      const disabledEvidences = []
      this.evidences.forEach(evidence => {
        if (evidence.isSelected) {
          selectedEvidences.push(evidence.code)
        } else if (evidence.isDisabled) {
          disabledEvidences.push(evidence.code)
        }
      })
      this.ghosts.forEach(ghost => {
        ghost.isDisabled = false
      })
      if (selectedEvidences.length) {
        this.ghosts.forEach(ghost => {
          let evidencesCount = 0
          ghost.evidences.forEach(ev => {
            if (selectedEvidences.includes(ev)) {
              evidencesCount++
            }
          })
          ghost.isDisabled =  !(selectedEvidences.length === evidencesCount)
        })
      }
      if (disabledEvidences.length) {
        this.ghosts.forEach(ghost => {
          let isDisabled = ghost.isDisabled
          ghost.evidences.forEach(ev => {
            if (disabledEvidences.includes(ev)) {
              isDisabled = true
            }
          })
          ghost.isDisabled =  isDisabled
        })
      }
    }
  }
}
</script>
