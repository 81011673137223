<template>
  <div id="app">
    <EvidencesList
        :evidences="evidences"
        @change="$refs.ghostsList.checkEvidences()"
        @evidenceInfo="showInfo"
    />
    <GhostsList
        ref="ghostsList"
        :evidences="evidences"
        :ghosts="ghosts"
        @ghostInfo="showInfo"
    />
    <InfoPopup ref="infoPopup"/>
  </div>
</template>

<script>

import EvidencesList from "@/components/EvidencesList";
import InfoPopup from "@/components/InfoPopup";
import GhostsList from "@/components/GhostsList";
import {EVIDENCES, GHOSTS} from "@/data";
export default {
  name: 'App',
  components: {GhostsList, InfoPopup, EvidencesList},
  data: () => ({
    evidences: EVIDENCES,
    ghosts: GHOSTS
  }),
  methods: {
    showInfo(item) {
      this.$refs.infoPopup.show(item)
    }
  }
}
</script>

<style src="./assets/styles.css"/>
