export const EVIDENCE_CODES = {
    emf: 'emf',
    dots: 'dots',
    fingerprints: 'fingerprints',
    orb: 'orb',
    notebook: 'notebook',
    radio: 'radio',
    temperature: 'temperature',
}

export const EVIDENCES = [
    {
        code: EVIDENCE_CODES.emf,
        title: 'ЭМП (ур. 5)',
        description: '5-ый уровень может "заменить" другой уровень с шансом 25%. <br><br>В брошенном ' +
            'состоянии датчик ЭМП никак не потеряет свои свойства в обнаружении этой улики, более ' +
            'того, он сможет обнаружить её на немного большем расстоянии (как и другие уровни).<br><br>Обычно, ' +
            '5-ый уровень появляется при разбрасывании предметов, открытии дверей, но не со 100% ' +
            'вероятностью, даже при условии наличия данной улики. Если же такая улика присутствует, ' +
            'то 100%-й метод проверки - щиток. Если Вы будете стоять с включенным датчиком ЭМП около ' +
            'щитка и призрак решит его выключить - Вам обязательно выдаст ЭМП (ур. 5) (опять же в том ' +
            'случае, если эта улика возможна для обнаружения). В случае отсутствия этой улики прибор ' +
            'может показать уровень от 2 до 3',
        isSelected: false,
        isDisabled: false,
    },
    {
        code: EVIDENCE_CODES.dots,
        title: 'Лазерный проектор',
        description: '',
        isSelected: false,
        isDisabled: false,
    },
    {
        code: 'fingerprints',
        title: 'Отпечатки рук',
        description: '',
        isSelected: false,
        isDisabled: false,
    },
    {
        code: EVIDENCE_CODES.orb,
        title: 'Призрачный огонёк',
        description: '',
        isSelected: false,
        isDisabled: false,
    },
    {
        code: EVIDENCE_CODES.notebook,
        title: 'Записи в блокноте',
        description: '',
        isSelected: false,
        isDisabled: false,
    },
    {
        code: EVIDENCE_CODES.radio,
        title: 'Радиоприёмник',
        description: '',
        isSelected: false,
        isDisabled: false,
    },
    {
        code: EVIDENCE_CODES.temperature,
        title: 'Минусовая температура',
        description: '',
        isSelected: false,
        isDisabled: false,
    },
]

export const GHOSTS = [
    {
        code: 'spirit',
        title: 'Дух',
        description: 'В сравнении с другими типами призраков, Дух относительно слаб. Он не имеет особых ' +
            'способностей и проявляет себя не так активно. Высокой агрессивностью этот призрак не обладает, ' +
            'но и тихо при этом себя не ведёт. Духа из-за его относительной нейтральности можно считать ' +
            'мерилом для других призраков, которые в разных показателях будут опаснее или спокойнее. ' +
            'Также известно, что он издаёт звуки шагов чаще, чем другие призраки.<br><br>' +
            'Сильные стороны: Дух не обладает уникальными способностями и в сравнении с другими ' +
            'призраками в целом представляет меньшую опасность для игроков, однако из-за его ' +
            'относительной пассивности находить улики и определять его тип сложнее.<br><br>' +
            'Слабые стороны: Дух сильнее других подвержен воздействию благовоний, которые ' +
            'останавливают его на целых 3 минуты (в 2 раза дольше, чем других призраков).',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.emf, EVIDENCE_CODES.radio, EVIDENCE_CODES.notebook],
    },
    {
        code: 'wraith',
        title: 'Мираж',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.emf, EVIDENCE_CODES.radio, EVIDENCE_CODES.dots],
    },
    {
        code: 'phantom',
        title: 'Фантом',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.dots],
    },
    {
        code: 'poltergeist',
        title: 'Полтергейст',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.notebook],
    },
    {
        code: 'banshee',
        title: 'Банши',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.dots, EVIDENCE_CODES.orb],
    },
    {
        code: 'jinn',
        title: 'Джинн',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.temperature, EVIDENCE_CODES.emf],
    },
    {
        code: 'mare',
        title: 'Мара',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.orb, EVIDENCE_CODES.notebook],
    },
    {
        code: 'revenant',
        title: 'Ревенант',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.orb, EVIDENCE_CODES.temperature, EVIDENCE_CODES.notebook],
    },
    {
        code: 'shade',
        title: 'Тень',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.temperature, EVIDENCE_CODES.notebook, EVIDENCE_CODES.emf],
    },
    {
        code: 'demon',
        title: 'Демон',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.temperature, EVIDENCE_CODES.notebook],
    },
    {
        code: 'yurei',
        title: 'Юрэй',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.dots, EVIDENCE_CODES.orb, EVIDENCE_CODES.temperature],
    },
    {
        code: 'oni',
        title: 'Они',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.dots, EVIDENCE_CODES.temperature, EVIDENCE_CODES.emf],
    },
    {
        code: 'yokai',
        title: 'Ёкай',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.dots, EVIDENCE_CODES.orb],
    },
    {
        code: 'hantu',
        title: 'Ханту',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.orb, EVIDENCE_CODES.temperature],
    },
    {
        code: 'goryu',
        title: 'Горё',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.dots, EVIDENCE_CODES.emf],
    },
    {
        code: 'myling',
        title: 'Мюлинг',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.notebook],
    },
    {
        code: 'onryo',
        title: 'Онрё',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.orb, EVIDENCE_CODES.temperature],
    },
    {
        code: 'twins',
        title: 'Близнецы',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.temperature, EVIDENCE_CODES.emf],
    },
    {
        code: 'raiju',
        title: 'Райдзю',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.dots, EVIDENCE_CODES.orb, EVIDENCE_CODES.emf],
    },
    {
        code: 'obake',
        title: 'Обакэ',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.orb, EVIDENCE_CODES.emf],
    },
    {
        code: 'mimic',
        title: 'Мимик',
        description: '',
        isSelected: false,
        isDisabled: false,
        evidences: [EVIDENCE_CODES.radio, EVIDENCE_CODES.fingerprints, EVIDENCE_CODES.temperature],
    },
]
